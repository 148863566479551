@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&family=Nanum+Gothic+Coding&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


/* ab51e3 */
/* SCROLLBAR customization */
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* color */
::-webkit-scrollbar-thumb {
  background: #4b4b4b;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #676c68;
}
::-webkit-scrollbar-track {
  background: #1f2220;
}
